<template>
  <card class="stacked-form" :title="$t('users.createUser')" :onCancel="cancel">
    <form @submit.prevent="registerUser">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.email')}} *</label>
          <div class="col-md-9">
            <base-input :placeholder="$t('users.email')" ref="emailInput" v-model=email> </base-input>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.employeeTitle')}}</label>
          <div class="col-md-9">
            <base-input :placeholder="$t('users.employeeTitle')" ref="employeeTitleInput" v-model=employeeTitle> </base-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.firstName')}} *</label>
          <div class="col-md-9">
            <base-input :placeholder="$t('users.firstName')" ref="firstNameInput" v-model=firstName> </base-input>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{ $t('users.lastName') }} *</label>
          <div class="col-md-9">
            <base-input :placeholder="$t('users.lastName')"  ref="lastNameInput" v-model=lastName> </base-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.identificationNumber')}} *</label>
          <div class="col-md-9">
            <base-input :placeholder="$t('users.identificationNumber')" ref="ssnInput" v-model=ssn> </base-input>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.phoneNumber')}} *</label>
          <div class="col-md-9">
            <base-input :placeholder="$t('users.phoneNumber')" ref="phoneNumberInput" v-model=phoneNumber> </base-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.role')}} *</label>
          <div class="col-md-9">
            <el-select :class="selectClass" v-model="role" ref="roleInput" :placeholder="$t('users.selectRole')">
              <el-option
                :class="selectClass"
                v-for="item in roleOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-3 col-form-label">{{$t('users.language')}} *</label>
          <div class="col-md-9">
            <el-select :class="selectClass" v-model="language" ref="languageInput" :placeholder="$t('users.selectLanguage')">
              <el-option
                :class="selectClass"
                v-for="(info, code) in languageMap"
                :key="code"
                :label="`${info.flag} ${info.name}`"
                :value="code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="col-sm d-flex justify-content-end">
        <base-button class="mt-3" native-type="submit" type="standard">{{$t('common.submit')}}</base-button>
      </div>
    </form>
  </card>
</template>
<script>
import api from "../../services/api"
import {Select, Option } from 'element-ui';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { extend } from 'vee-validate';
import { required, confirmed } from 'vee-validate/dist/rules';

// Add the required rule
extend('required', required);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
data() {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    ssn: '',
    employeeTitle: '',
    language: '',
    role: '',
    languageMap: {
      'en-US': { name: 'English', flag: '🇺🇸' },
      'is-IS': { name: 'Icelandic', flag: '🇮🇸' },
      'pl-PL': { name: 'Polski', flag: '🇵🇱' },
      'lt-LT': { name: 'Lietuvis', flag: '🇱🇹' }
    },
    roleOptions: ['Admin','User']
  };
},
mounted() {
},
methods: {
  cancel() {
    this.$emit('close');
  },
  async registerUser() {
    if (this.validateInput()) {
      const decodedToken = jwtDecode(Cookies.get('accessToken'));
      const response = await api.post('/identity/RegisterUser', {
        customerId: decodedToken.CustomerId,
        email: this.email,
        roleName: this.role,
        phoneNumer: this.phoneNumber,
        identificationNumber: this.ssn,
        firstName: this.firstName,
        lastName: this.lastName,
        language: this.language,
        employeeTitle: this.employeeTitle
      }).then(response =>
      {
        this.$notify({
          message: `${this.firstName + " "  + this.lastName} ${this.$t('users.created')}`,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success",
          timeout: 1500,
        });
      }).catch(error =>
      {
          console.error('API request error:', error);
          this.$notify({
            message: `${this.$t('users.unableToCreate')} ${this.firstName + " " + this.lastName} <br> ${error.message}: ${error.response.data[0].errorMessage}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
      });

      //TODO: Route to user site rather than refresh and close!
      this.$emit('refreshData');
      this.$emit('close');
    }

  },
  validateInput() {
    let success = true;

    if (this.email === '') {
      this.$refs.emailInput.focus();
      this.$notify({
        message: this.$t('errors.emailEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.firstName === '') {
      this.$refs.firstNameInput.focus();
      this.$notify({
        message: this.$t('errors.firstNameEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.lastName === '') {
      this.$refs.lastNameInput.focus();
      this.$notify({
        message: this.$t('errors.lastNameEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.phoneNumber === '') {
      this.$refs.phoneNumberInput.focus();
      this.$notify({
        message: this.$t('errors.phoneNumberEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.ssn === '') {
      this.$refs.ssnInput.focus();
      this.$notify({
        message: this.$t('errors.ssnEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.role === '') {
      this.$refs.roleInput.focus();
      this.$notify({
        message: this.$t('errors.roleEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.language === '') {
      this.$refs.languageInput.focus();
      this.$notify({
        message: this.$t('errors.languageEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    return success;
  }
}
};
</script>
<style></style>
